import React from "react"
import styled from "styled-components"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 60px auto 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-bottom: 100px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 90px;
    }
`;

const Content = styled.div `
    width: 100%;
    padding-top: 60px;
    
    .row {
        justify-content: space-between;
    }

    .column {
       max-width: 45%;
    }
    
    @media only screen and (max-width: 1140px) {
        padding: 0;

        .column {
            max-width: unset;
         }

         img {
            max-width: 500px;
         }
    }
    
    @media only screen and (max-width: 768px) {
        img {
            max-width: 100%;
         }
    } 

    .img-container {
        display: flex;
        justify-content: center;
        width: 100%
    }
`;

const VideoIFrame = styled.iframe `
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 0;
    border: 0;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        max-width: 100%;
        height: 280px!important;
        min-height: 280px;
    }
`;

class ContentClass extends React.Component {
    constructor(props) {
        super();
        this.location = props.location.location;
        const params = new URLSearchParams(this.location.search)

        this.state = {
            watch_video: this.location.search && this.location.search.includes('watch_video'),
            tracking_source: this.location.search && this.location.search.includes('utm_source') ? params.get('utm_source') : 'Other',
            submitted: this.location.search && this.location.search.includes('submit')
        };
    }

    render() {
        return (
            <WrapperDiv id="supplyChainContent">
                <Flex>
                   <Content>
                    <p>This series of short videos provides a quick overview of the Certinia (formally FinancialForce) Supply Chain application. Watch the videos in the series or jump to a specific topic of interest. </p>
                    <div className="row">
                        <div className="column col-6">
                            <div className="service-block">
                                <span className="content">
                                    <VideoIFrame src="https://player.vimeo.com/video/705381732?h=70a47a84ab" className="videoIframe" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></VideoIFrame>
                                    <h3 className="margin-bottom-0">Introduction</h3>
                                    <p>Introduction to the Certinia Supply Chain (OPI) product & capabilities.</p>
                                </span>
                            </div>
                        </div>
                        <div className="column col-6">
                            <div className="service-block">
                                <span className="content">
                                    <VideoIFrame src="https://player.vimeo.com/video/718107779?h=3e48615e7d" className="videoIframe" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></VideoIFrame>
                                    <h3 className="margin-bottom-0">Quoting</h3>
                                    <p>Understanding the advanced quoting modules features, and why customers use it.</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column col-6">
                            <div className="service-block">
                                <span className="content"> 
                                    <VideoIFrame src="https://player.vimeo.com/video/729749974?h=0186578db3" className="videoIframe" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></VideoIFrame>
                                    <h3 className="margin-bottom-0">Inventory & Order Management</h3>
                                    <p>Learn how inventory is managed in the system and how customer orders are fulfilled.</p>
                                </span>
                            </div>
                        </div>
                        <div className="column col-6">
                            <div className="service-block">
                                <span className="content">
                                    <VideoIFrame src="https://player.vimeo.com/video/734185840?h=37615ad93" className="videoIframe" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></VideoIFrame>
                                    <h3 className="margin-bottom-0">Procurement</h3>
                                    <p>See how customers acquire inventory and manage spending using the Procurement module.</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row last">
                        <div className="column col-6">
                            <div className="service-block">
                                <span className="content">
                                    <VideoIFrame src="https://player.vimeo.com/video/747265540?h=83906cb9c8" className="videoIframe" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></VideoIFrame>
                                    <h3 className="margin-bottom-0">Financials</h3>
                                    <p>Learn how inventory transactions automatically create related Financial records in FM.</p>
                                </span>
                            </div>
                        </div>
                    </div>
                   </Content>
                </Flex>
        </WrapperDiv>
        )
    }
}

export default ContentClass
