import React from "react"
import { Location } from '@reach/router'
import Hero from "../../components/hero-supplychain"
import Content from "../../components/content-supplychain"

import "../../assets/index.css"
import Layout from "../../layouts/layout"

export default () => (
    <Layout title="Supply Chain Education | Ladd Partners">
        <Hero/>

        <Location>
            {({ location }) => {
                return  <><Content location={{ location }} /></>
            }}
        </Location>
    </Layout>
)
